import * as React from 'react'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { Card } from 'baseui/card'
import { H1, H5, Paragraph1, Paragraph2, Paragraph3 } from 'baseui/typography'
import { useStyletron, styled } from 'baseui'
import BaseLayout from '../layout/base'
import portrait from '../assets/portrait.jpg'
import baseWebLogo from '../assets/baseweblogo.svg'
import codeIcon from '../assets/code.svg'
import frontEndCodeIcon from '../assets/frontend-code.svg'


const Container = styled('div', {
  maxWidth: '1280px',
  margin: '0 auto',
  padding: '1rem',
})

const Intro = () => {
  const [css] = useStyletron()
  return (
    <div className={
      css({
        margin: '3rem 0',
        minHeight: '100px',
        textAlign: 'center'
      })
    }>
      <Container>
        <H1>Full Stack Software Developer</H1>
        <Paragraph1>I enjoy writing code to solve problems and create useful tools.</Paragraph1>
        <img className={
          css({
            border: 0,
            borderRadius: '50%',
            boxShadow: '2px 4px 5px rgb(0,0,0,0.3)',
            margin: '35px 0 20px 0',
            width: '100%',
            height: 'auto',
            maxWidth: '300px'
          })
        } src={portrait} alt="collier-devlin-portrait" />
      </Container>
    </div>
  )
}

const About = () => {
  const [css] = useStyletron()
  return (
    <div className={
      css({
        background: '#86A0A8',
        minHeight: '100px',
        paddingBottom: '100px',
        textAlign: 'center'
      })
    }>
      <Container>
        <H5 color="white">Hi, I'm Collier. Great to meet you!</H5>
        <Paragraph1 color="white" className={
          css({
            margin: '0 auto 50px auto',
            maxWidth: '650px'
          })
        }>
          Since I began my career 7 years ago, I've quickly become an expert with front-end development, being a team leader focusing on UI design and React / Vue JS. Along the way, I have learned numerous back-end languages to help create and integrate APIs with my interfaces. Aside from web development, I've recently taken a deep dive into native mobile app development for Android.
        </Paragraph1>
      </Container>
    </div>
  )
}

const SkillListing = ({ title, list }) => {
  const [css] = useStyletron()
  return (
    <div className={
      css({
        marginTop: '45px'
      })
    }>
      <Paragraph1 className={css({ fontWeight: 'bold', color: '#86A0A8' })}>{title}:</Paragraph1>
      {
        list.map(({ name }) => (
          <Paragraph2>{name}</Paragraph2>
        ))
      }
    </div>
  )
}

const Skills = () => {
  const itemProps = {
    minHeight: 'scale1000',
    minWidth: '250px',
    flexShrink: 0,
    textAlign: 'center'
  }
  const [css] = useStyletron()
  return (
    <div className={
      css({
        marginTop: '-90px',
        padding: '0 15px',
        textAlign: 'center'
      })
    }>
      <Card overrides={{
        Root: {
          style: {
            border: '2px solid #f1f1f1',
            borderRadius: '5px',
            boxShadow: '2px 4px 5px rgb(0,0,0,0.1)',
            margin: '0 auto 50px auto',
            maxWidth: '1080px',
            width: '100%',
            minHeight: '300px',
          }
        }
      }}>
        <FlexGrid
          flexGridColumnCount={2}
          flexGridRowGap="scale800"
          flexWrap
        >
          <FlexGridItem {...itemProps}>
            <div className={
              css({ padding: '0 3rem' })
            }>
              <img className={
                css({
                  color: '#86A0A8',
                  margin: '35px 0 10px 0',
                  height: '100%',
                  width: 'auto',
                  maxHeight: '75px'
                })
              } src={frontEndCodeIcon} alt="frontend-code-icon-svg" />
              <H5>Front-end</H5>
              <Paragraph1 className={css({ margin: '40px 0' })}>I focus on simplicity for the users and the developers reading my code.</Paragraph1>
              <SkillListing title="Frameworks" list={[
                { name: 'React JS' },
                { name: 'Vue JS' },
                { name: 'Typescript' },
                { name: 'Kotlin (Android)' }
              ]} />
              <SkillListing title="Tools" list={[
                { name: 'VS Code' },
                { name: 'Figma' },
                { name: 'Git' }
              ]} />
            </div>
          </FlexGridItem>
          <FlexGridItem {...itemProps}>
            <div className={
              css({ padding: '0 3rem' })
            }>
              <img className={
                css({
                  margin: '50px 0 20px 0',
                  height: '100%',
                  width: 'auto',
                  maxHeight: '50px'
                })
              } src={codeIcon} alt="code-icon-svg" />
              <H5>Back-end</H5>
              <Paragraph1 className={css({ margin: '40px 0' })}>I am an advocate for microservices and enjoy writing simple, explicit endpoints.</Paragraph1>
              <SkillListing title="Languages" list={[
                { name: 'Kotlin/Java' },
                { name: 'Node JS' },
                { name: 'Golang' },
                { name: 'PHP/Laravel' },
              ]} />
              <SkillListing title="Tools" list={[
                { name: 'Intellij' },
                { name: 'Android Studio' },
                { name: 'Git' }
              ]} />
            </div>
          </FlexGridItem>
        </FlexGrid>
      </Card>
    </div>
  )
}

const Footer = () => {
  const [css] = useStyletron()
  return (
    <div className={
      css({
        textAlign: 'center',
        margin: '25px auto 10px auto'
      })
    }>
      <Paragraph3 className={css({ marginTop: '5rem', opacity: '0.6' })}>
        Made with
      </Paragraph3>
      <a href="https://baseweb.design/" target="__blank">
        <img className={
            css({
              width: '100%',
              height: 'auto',
              maxWidth: '85px',
              opacity: '0.6',
              ':hover': { opacity: '0.9', cursor: 'pointer' }
            })
          } src={baseWebLogo} alt="base-web-logo" />
      </a>
      
      <Paragraph2 className={css({ margin: '45px 0 25px 0' })} color="#86A0A8">
        Created by me &copy; {(new Date()).getFullYear()}
      </Paragraph2>
    </div>
  )
}

const IndexPage = () => (
  <BaseLayout>
    <Intro />
    <About />
    <Skills />
    <Footer />
  </BaseLayout>
)

export default IndexPage
