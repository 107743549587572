import * as React from "react"
import { AppNavBar } from "baseui/app-nav-bar"
import { useStyletron } from "baseui"
import logo from '../assets/cd-logo.svg'
import resume from '../files/2021-resume.pdf'

const AppBar = () => {
  const navLinkStyle = { textDecoration: 'none', color: 'inherit' }
  const [css] = useStyletron()
  const [mainItems] = React.useState([
    { label: <a id="download-resume" key="resume" className={css(navLinkStyle)} href={resume} download>Resume</a> },
    { label: <a id="linkedin-link" key="contact" className={css(navLinkStyle)} href="https://www.linkedin.com/in/collier-devlin-612b7854/" target="__blank">Contact</a> },
  ]);
  const [selectedItem, setSelectedItem] = React.useState(null)

  React.useEffect(() => {
    if (selectedItem !== null) {
      if (typeof selectedItem?.label === 'object' && selectedItem.label?.key === 'resume') {
        document.getElementById('download-resume')?.click()
      } else if (typeof selectedItem?.label === 'object' && selectedItem.label?.key === 'contact') {
        document.getElementById('linkedin-link')?.click()
      }
    }
  }, [selectedItem])

  return (
    <AppNavBar
      title={
        <div className={
          css({
            height: '50px',
            position: 'relative'
          })
        }>
          <img src={logo} alt="cd-logo" className={
            css({
              width: 'auto',
              height: '80%',
              maxHeight: '50px',
              margin: '0 auto',
              position: 'absolute',
              bottom: '5px'
            })
          } />
        </div>
      }
      mainItems={mainItems}
      onMainItemSelect={item => {
        setSelectedItem(item)
      }}
      overrides={{
        Root: {
          style: () => ({
            background: 'transparent',
            boxShadow: 'none'
          })
        },
      }}
    />
  );
}

export default AppBar
